import * as Sentry from '@sentry/astro';

Sentry.init({
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  environment: import.meta.env.PUBLIC_SENTRY_ENV,
  integrations: [Sentry.extraErrorDataIntegration({ depth: 10 })],
  sampleRate: 0.5,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.8,
  enabled: import.meta.env.PUBLIC_SENTRY_ENABLE === 'true',
  debug: import.meta.env.PUBLIC_SENTRY_DEBUG === 'true'
});
